<template>
  <div>
    <el-select
      v-model="selectedAddress"
      style="width: calc(100% - 20px)"
      ref="field"
      :disabled="isDisabled"
      filterable
      clearable
      remote
      value-key="key"
      :loading="loading"
      :remote-method="search"
      :placeholder="placeholder"
      @focus="setDefaultAddress"
      @clear="clearSelectedAddress"
    >
      <i slot="prefix" class="el-icon-search" style="margin-left: 5px; font-weight: bold"></i>
      <el-option
        v-for="item in searchOptions"
        :key="item.key"
        :label="getAddressLabel(item)"
        :value="item"
        class="el-option-content">
        <div class="el-option-text" @click="changeSelected(item);">
          {{ getAddressLabel(item) }}
        </div>
        <div class="el-option-buttons">
          <el-button type="text" size="small" class="el-option-button" @click="changeSelected(item, true)">
            <i class="el-icon-setting el-icon--left"></i>
            {{ $locale.address_service.button.specify }}
          </el-button>
        </div>
      </el-option>
    </el-select>
    <i
      v-if="!isDisabled"
      :class="{'el-icon-arrow-down': !showAddressObjects, 'el-icon-arrow-up': showAddressObjects}"
      style="margin-left: 5px; font-weight: bold; cursor:pointer;"
      @click="showAddressObjects = !showAddressObjects"></i>
    <address-objects
      :is-active.sync="showAddressObjects"
      :dropdown-width="dropdownWidth"
      :level="level"
      :required-level="requiredLevel"
      :selected-address="selectedAddress"
      @set-address="setSelectedAddress"
      style="margin-top: 5px"
    ></address-objects>
  </div>
</template>

<script>
import AddressObjects from '@/core/infrastructure/components/AddressField/AddressObjects'
export default {
  name: 'address_field',
  components: { AddressObjects },

  props: {
    level: {
      type: String,
      default: 'street'
    },
    placeholder: {
      type: String
    },
    searchLimit: {
      type: Number,
      default: 50
    },
    value: {
      type: Object
    },
    requiredLevel: {
      type: String
    },
    defaultAddress: {
      type: String
    },
    isDisabled: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      selectedAddress: this.value || {},
      searchOptions: [],
      showAddressObjects: false,
      dropdownWidth: 100,
      loading: false
    }
  },

  computed: {
    resultedValue () {
      if (Object.keys(this.selectedAddress || {}).length === 0) {
        return null
      }
      let result = this.selectedAddress

      result.id = this.selectedAddress.address_id || null
      result.regioncode = this.selectedAddress.regioncode || null
      result.house_houseid = this.selectedAddress.house_houseid || null
      result.addrobj_aoid = this.selectedAddress.addrobj_aoid || null
      result.address_level_id = this.selectedAddress.address_level_id || null
      result.house = this.selectedAddress.house || null
      result.flat = this.selectedAddress.flat || null
      result.description = this.selectedAddress.description || null // По умолчанию null, на сервере проверка дублей по NULL

      return result
    }
  },

  watch: {
    value: {
      handler (newValue) {
        this.searchOptions = [newValue]
        if (newValue?.address_id !== this.selectedAddress?.address_id) {
          this.selectedAddress = newValue
        }
      },
      deep: true
    },
    selectedAddress: {
      handler () {
        this.$emit('input', this.resultedValue)
      },
      deep: true
    },
    showAddressObjects (value) {
      if (value) {
        this.updateDropdownWidth()
      }
    }
  },

  mounted () {
    this.updateDropdownWidth()
  },

  methods: {
    updateDropdownWidth () {
      this.dropdownWidth = this.$el.offsetWidth > 500 ? 500 : this.$el.offsetWidth - 20
    },
    setDefaultAddress () {
      if (this.defaultAddress) {
        const inputEl = this.$refs.field.$el.children[0].children[0]
        inputEl.value = this.defaultAddress
        this.search(this.defaultAddress)
      }
    },
    async setSelectedAddress (address) {
      if (Object.keys(address || {}).length === 0) {
        return
      }

      let option = await this.loadAddress(address) || {}

      if (Object.keys(option).length > 0) {
        this.selectedAddress = option
      } else {
        this.selectedAddress = address
      }
    },
    async loadAddress (address) {
      if (Object.keys(address.data || {}).length === 0 || !address.type) {
        return
      }

      let url = null
      if (address.type === 'house') {
        if (address.data.house_houseid) {
          url = `${this.$config.api}/fiasaddressservice/addresses/houses?house_houseid=${address.data.house_houseid}`
        } else {
          url = `${this.$config.api}/fiasaddressservice/addresses/houses?addrobj_aoid=${address.data.previous}&house=${address.data.house}`
        }
      } else {
        url = `${this.$config.api}/fiasaddressservice/addresses/address_objects/${address.data.id}`
      }
      if (!url) {
        return false
      }
      const fiasData = await this.$http.get(url)
      const fiasAddress = fiasData.data
      if (!fiasAddress) {
        console.warn('address not found')
        return false
      }

      let option = fiasAddress
      /* Other fields */
      this.$set(option, 'flat', address.flat)
      // По умолчанию null, на сервере проверка дублей по NULL
      this.$set(option, 'description', address.description || null)

      // Для привязки существующего адреса по id (не address_id). Новый адрес если заполнен description.
      // - Может быть заполнен из-за выбора flat (см. address_id_flat в save в AddressObject.vue)
      if (address.id) {
        // ID на входе в функцию loadAddress есть, привязать существующий адрес по ID
        this.$set(option, 'id', address.id)
      } else if (option.address_id && !address.flat && !address.description) {
        // Привязать к существующему адресу только при не заполненом flat и description
        this.$set(option, 'id', option.address_id)
      }
      // address_id от сервера привязанный к house, приравниваем к id, чтобы не сбивало с толку при отладке
      this.$set(option, 'address_id', option.id)

      // Для сохранения flat, обязателен address_level_id == 'flat'
      this.$set(option, 'address_level_id', option.flat ? 'flat' : option.address_level_id)

      // Ключ адреса для проверки дублей с учётом flat, description
      const key =
        `${option.subject_guid}_${option.municipal_area_guid}_${option.city_guid}_${option.locality_guid}_${option.planning_structure_guid}_${option.street_guid}` +
        `_${option.addrobj_aoid}_${option.house_houseid}_${option.address_level_id}_${address.flat}_${address.description}`
      this.$set(option, 'key', key)

      if (!this.searchOptions.find(item => item.key === option.key)) {
        // Адрес с ключем не найден, добавить адрес в список поиска
        this.searchOptions.push(option)
      }

      return option
    },
    async search (query) {
      if (!query) {
        this.searchOptions = []
        return false
      }
      this.loading = true
      query = encodeURIComponent(query)
      const fiasData = await this.$http.get(`${this.$config.api}/fiasaddressservice/addresses/address_objects/${this.level}/${query}?limit=${this.searchLimit}`)
      this.searchOptions = fiasData.data.map(fiasAddress => {
        // Ключ адреса для проверки дублей
        const key =
          `${fiasAddress.subject_guid}_${fiasAddress.municipal_area_guid}_${fiasAddress.city_guid}_${fiasAddress.locality_guid}_${fiasAddress.planning_structure_guid}_${fiasAddress.street_guid}` +
          `_${fiasAddress.addrobj_aoid}_${fiasAddress.address_id}_${fiasAddress.house_houseid}_${fiasAddress.address_level_id}`

        return Object.assign(fiasAddress, { key })
      })

      this.loading = false
    },
    changeSelected (address, isShowAddressObjects = false) {
      if (!address) {
        return
      }

      // address_id от сервера привязанный к house, приравниваем к id
      this.$set(address, 'id', address.address_id)
      /* Other fields */
      // По умолчанию null, на сервере проверка дублей по NULL
      this.$set(address, 'description', address.description || null)

      this.selectedAddress = address

      if (isShowAddressObjects) {
        // Показать модальное окно адреса
        this.showAddressObjects = true
      }
    },
    clearSelectedAddress () {
      this.selectedAddress = null
    },
    getAddressLabel (address) {
      let addressLabel = address.name // Может содержать flat

      if (address.flat && !addressLabel.includes(', кв.') /* Проверить содержание flat */) {
        addressLabel += ', кв. ' + address.flat
      }

      if (address.description) {
        addressLabel += ', ' + address.description
      }

      return addressLabel
    }
  }
}
</script>

<style scoped>
.el-option-content {
  display: flex;
}

.el-option-text {
  float: left;
  width: 100%;
}

.el-option-buttons {
  float: right;
}

.el-option-button {
  width: 75px;
}
</style>
